import { InfoContainer, NextButton, PreviousButton, ButtonWrapper } from '../styles-components';
import Section1ImgSend from './fsq-section-1-send.png';
import Section2Img from './fsq-section-2.png';
import Section3Img from './fsq-section-3.svg';
import Section3ImgSend from './fsq-section-3-send.png';

export default function SectionDivide({
  onNext,
  onPrevious,
  part,
  isSend,
}: {
  onNext: () => void;
  onPrevious: () => void;
  part: 1 | 2 | 3;
  isSend: boolean;
}) {

  if (part === 1) {
    return (
      <InfoContainer>
        <img src={Section1ImgSend} width="200px" height="186px" alt="lightbulb"></img>
        <h2>Career Knowledge and Skills</h2>
          <div>
              <p className={"highlight"}>A career describes your journey through life, learning and work</p><br />
              <p>
                  If your friends all know what they want to do when they leave school or college and you don’t, it
                  can
                  be scary but it’s totally normal to feel a little bit lost. That’s where answering these questions
                  can help.
              </p><br />
              <p>
                  They will get you thinking about the different options that are out there and what you might be
                  able to do in the future.
              </p>
          </div>
        <ButtonWrapper>
          <PreviousButton onClick={onPrevious}>Go Back</PreviousButton>

          <NextButton onClick={onNext}>Continue</NextButton>
        </ButtonWrapper>
      </InfoContainer>
    );
  }

  if (part === 2) {
      return (
          <InfoContainer>
              <img src={Section2Img} width="193px" height="186px" alt="handshake"></img>

              <h2>Essential Skills for Life and Work</h2>
              <div>
                  <p className={"highlight"}>Skills are personal qualities and things someone is able to do.</p><br/>
                  <p>
                      These questions will help you think about what you’re good at and also the areas that you need to
                      work on to develop and improve.
                  </p><br/>
                  <p>
                      Remember, this isn’t a test! There are no right or wrong answers, just take your time, read
                      through
                      each question carefully and pick the right answer for you.
                  </p>
              </div>
              <ButtonWrapper>
                  <PreviousButton onClick={onPrevious}>Go Back</PreviousButton>
                  <NextButton onClick={onNext}>Continue</NextButton>
              </ButtonWrapper>
          </InfoContainer>
      );
  }

    return (
      <InfoContainer>
          {isSend ?
            <img src={Section3ImgSend} width="280px" height="186px" alt="writing note"></img>:
            <img src={Section3Img} width="200px" height="186px" alt="man with question lightbulb above his head"></img>
          }

        <h2>Career Planning</h2>
          {isSend ?
              <div>
                  <p className={"highlight"}>Working towards goals in life that you want to achieve.</p><br/>
                  <p>
                      These questions ask about the support you have that will help you make plans for the future.
                  </p><br/>
                  <p>
                      It’s important to remember that everyone’s plan will be unique and special to them, so don’t worry
                      if
                      your plans for the future look different to someone else’s.
                  </p><br/>
                  <p>
                      Don’t forget, there are no right or wrong answers, just take your time, read through each question
                      carefully and pick the right answer for you.
                  </p><br/>
                  <p>
                      Each answer has an emoji next to it too, so if you’re not sure what the words say, you can pick
                      the
                      emoji that best fits how you feel about the question.
                  </p>
              </div> :
              <ul>
                <li>The next two questions are about career planning.</li>
                <li>Remember that there are no right or wrong answers and to take your time to carefully read and answer each question. </li>
            </ul>
          }
        <ButtonWrapper>
          <PreviousButton onClick={onPrevious}>Go Back</PreviousButton>
          <NextButton onClick={onNext}>Continue</NextButton>
        </ButtonWrapper>
      </InfoContainer>
    );
}
