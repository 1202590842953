import { InfoContainer, NextButton } from '../styles-components';
import { HelpSubtext } from '../Question/styles';
import WelcomeImg from "./WelcomeToTheFutureSkillsQuestionnaire.png";


export default function Welcome({
  onClick,
  studentName,
}: {
  onClick: () => void;
  studentName: string;
}) {

  return (
    <InfoContainer>
      <img src={WelcomeImg} width="200px" height="270px" alt="signpost"></img>
        <h2>Welcome to the Future Skills Questionnaire</h2>
        {studentName ? (
            <HelpSubtext>
          <span title={'Not your name? Speak to your teacher as you may have the incorrect questionnaire link.'}>
            {studentName}*
          </span>
        </HelpSubtext>
      ) : (
        <></>
      )}
      <p style={{ fontWeight: 'bold' }}>
        The Future Skills Questionnaire is a set of questions that have been designed for young people in schools
        and colleges to help you think about your skills, strengths and what you might like to do in the future.
      </p><br/>
      <p>
        Your responses will be shared with your Careers Leader to help make your careers programme better.
      </p><br/>
      <p>
        Your responses will help your Careers Leader to make sure that every young person in your school is
        supported to make the right choice for their next step after school/college.
      </p><br/>
      <p>
        The questionnaire takes about 15 minutes to complete. It isn’t a test! There are no right or wrong answers,
        just take your time, read through each question carefully and pick the right answer for you.
      </p><br/>
      <p>
        Each answer has an emoji next to it too, so if you’re not sure what the words say, you can pick the
        emoji that best fits how you feel about the question.
      </p><br/>
      <p>
        (And just so you know…your information will be stored safely and handled in line with the Future
        Skills Questionnaire privacy policy.)
      </p>

      <NextButton onClick={onClick}>Continue</NextButton>
    </InfoContainer>
  );
}
