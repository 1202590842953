import { InfoContainer } from '../styles-components';
import Error from '../Error';
import SubmitImage from './fsq-submitted.png';

export default function Submitted({ status }: { status: 'loading' | 'success' | 'error' | 'ServerSide' | 'preview' }) {
  if (status === 'success') {
    return (
      <InfoContainer>
        <img src={SubmitImage} width="565px" height="280px" alt="group of students"></img>

        <h2 style={{marginBottom: 0}}>Thanks for taking the time to complete the </h2>
        <h2 style={{marginTop: 0}}> Future Skills Questionnaire!</h2>
        <p>If you have any questions or concerns after completing the questionnaire, please talk to your teacher.</p>
        <p>&nbsp;</p>
        <p>You may now close your browser window.</p>
      </InfoContainer>
    );
  } else if (status === 'loading') {
    return <Error error="Loading"></Error>;
  } else if (status === 'ServerSide') {
    return <Error error="ServerSide"></Error>;
  } else if (status === 'preview') {
    return (
      <InfoContainer>
        <img src={SubmitImage} width="565px" height="280px" alt="group of students"></img>

        <h2>You have just finished previewing the Future Skills Questionnaire.</h2>
        <p>&nbsp;</p>
        <p>Your responses have not been saved as this is a preview of the questionnaire.</p>
      </InfoContainer>
    );
  } else {
    return <Error error="PostError"></Error>;
  }
}
